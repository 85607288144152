import React from 'react';
import { Avatar } from 'antd';
import DashboardLayout from '@components/DashboardLayout';
import Commission from '@modules/dashboard/Commission/Commission';
import { useGetTransactionsStartDateQuery } from '@app/services/api/transaction';
import { useGetCognitoUserInfoQuery, useGetUserSettingsQuery } from '@app/services/api/userSetting';
import styles from './index.module.scss';
export default function Dashboard() {
  const { data: user = {} } = useGetCognitoUserInfoQuery();
  const { data: client = {} } = useGetUserSettingsQuery();
  const { data: activeWithdrawal = [] } = useGetTransactionsStartDateQuery({
    adminApproval: 'PROCESSING',
    transactionType: 'WITHDRAW',
  });

  const headerContent = (
    <div className={styles.pageHeaderContent}>
      <div className={styles.avatar}>
        <Avatar size="large" src="/icons/user/user-icon.png" />
      </div>
      <div className={styles.content}>
        <div className={styles.contentTitle}>
          Hello, <strong>{user.username}</strong>! Hope you are having a good day!
        </div>
        <div>
          {user.email} | {user.phone_number}
        </div>
      </div>
      <div className={styles.extraContent}>
        <div className={styles.statItem}>
          <p>Clients</p>
          <p>{client.count || 0}</p>
        </div>
        <div className={styles.statItem}>
          <p>Active Withdrawal</p>
          <p>{activeWithdrawal.length}</p>
        </div>
      </div>
    </div>
  );

  return (
    <DashboardLayout pageTitle="Dashboard" headerContent={headerContent}>
      <Commission />
    </DashboardLayout>
  );
}
