import React from 'react';
import { Card, Table } from 'antd';
import RefreshButton from '@components/RefreshButton';
import styles from '@pages/dashboard/index.module.scss';
import { useGetAdminAccountQuery } from '@app/services/api/dashboard';

class CreateColumn {
  // eslint-disable-next-line class-methods-use-this
  addCol(param, render) {
    return {
      title: param,
      dataIndex: param,
      key: param === 'Username' ? 'username' : param,
      render,
    };
  }
}

function Commission() {
  const { data: adminCommission = [], refetch } = useGetAdminAccountQuery();
  const createColumn = new CreateColumn();
  const firstColumn = createColumn.addCol('Username', (text, row) => <div>{row.username}</div>);
  const coinList = adminCommission[0]
    ? Object.keys(adminCommission[0]).filter((ex) => ex !== 'username' && ex !== 'key')
    : [];

  const otherColumns = coinList.map((currency) =>
    createColumn.addCol(currency, (text, row) => {
      const balance = row[currency] || '-';
      return <span>{balance}</span>;
    }),
  );
  const columns = [firstColumn, ...otherColumns];

  return (
    <Card title="Aqua Commission" extra={<RefreshButton onClick={refetch} />}>
      <Table
        className={styles.exchangeBalance}
        size="small"
        columns={columns}
        dataSource={adminCommission}
        rowKey={(record) => record.key}
        bordered
        pagination={false}
      />
    </Card>
  );
}

export default Commission;
