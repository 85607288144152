import React from 'react';
import { Button, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

function RefreshButton({ disabled = false, onClick }) {
  return (
    <div className={styles.refreshButton}>
      <Tooltip placement="bottomRight" title="Refresh">
        <Button disabled={disabled} type="primary" onClick={onClick}>
          <ReloadOutlined />
        </Button>
      </Tooltip>
    </div>
  );
}

export default RefreshButton;
